<template>
  <tooltip-icon
    :icon="options.icon"
    :text="options.text"
    :color="options.color"
    large
    class="lighten-2"
  />
</template>

<script>
import { getOptionsByStatus } from "@/utils/subscriptions-status";

export default {
  name: "SubscriptionStatusChip",
  props: {
    status: {
      type: String,
      required: true,
    },
    textClass: {
      type: String,
      default: "body-2",
    },
  },
  computed: {
    options() {
      return getOptionsByStatus(this.status);
    },
  },
};
</script>

<style scoped>
div {
  max-width: 100px;
  word-break: normal;
}
</style>
